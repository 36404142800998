import React from "react"
import Layout from "../../components/Layout"

const Support = () => {
  return (
    <Layout>
      <div className="support__picture" />
      <section className="claimants__detail">
        <h1 className="claimants__detail-title">
          How Churches Can Support Claimants
        </h1>
        <p>
          <strong>Refugee claimants</strong> are particularly vulnerable as
          there are very few government programs set up to help them. The Church
          is uniquely suited to walk alongside refugee claimants and help them
          navigate the various systems they will encounter on arrival in Canada.
        </p>
        <p>
          Often the biggest unmet need of newly arrived refugee claimants is
          community connection. Whether you meet a newly arrived refugee in your
          neighbourhood, at work, at church, or through a community
          organization, you have the opportunity to welcome them. Let them know
          you are glad they are here. Your kindness and genuine welcome will
          make a difference!
        </p>
        <p>
          There are many practical ways for your church to support refugee
          claimants. If your church would like to explore options, and hear what
          other churches are doing, we highly recommend you register as an
          official{" "}
          <a href="https://welcomechurch.ca/" target="_blank" rel="noreferrer">
            Welcome Church
          </a>{" "}
          which will give you access to in-depth training videos and further
          resources. Click{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfmX1T81q-XPXzHxLUxl8MypwTwQamQaW_NduW_dTYI_jry8A/viewform"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          to register.
        </p>
        <p>Here are just some suggestions and resources to get you started.</p>
        <h4>Some ways to engage with refugee claimants</h4>
        <ul className="claimants__list">
          <li>
            Invite newly arrived refugee claimants into your community. Build
            friendships. Invite them into your home. Perhaps even more
            importantly, be ready to receive their hospitality and take a seat
            at their table
          </li>
          <li>
            Provide safe short-term initial housing and/or long-term affordable
            housing
          </li>
          <li>Create opportunities for language practice and tutoring</li>
          <li>
            Assist with writing a job resume and making connections to possible
            employers
          </li>
          <li>Organize pick-up soccer games in your neighbourhood</li>
          <li>
            Advocate for just and compassionate government policies for refugee
            claimants
          </li>
          <li>
            Connect with a local organization that supports refugee claimants
            such as refugee shelters. They are often looking for volunteers
          </li>
        </ul>
        <h4>Resources</h4>
        <ul className="claimants__list">
          <li>
            <i>Finding Our Way: Immigrants, Refugees and Canadian Churches</i>.
            A{" "}
            <a
              href="https://docs.wixstatic.com/ugd/36eba7_49052b263e65473cb84d99d0308ca9b5.pdf"
              target="_blank"
              rel="noreferrer"
            >
              free 30-page PDF
            </a>
          </li>
        </ul>
      </section>
    </Layout>
  )
}

export default Support
